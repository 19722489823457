import styled from 'styled-components';

export const ActionList = styled.div`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translate(100%, -50%);
  margin-bottom: 10px;
  user-select: none;
`;
