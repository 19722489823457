import styled from 'styled-components';

export const Text = styled.div`
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.dispatchersWorkplace.sidebarMenu.menuItem};

  @media (max-width: 1700px) {
    font-size: 15px;
  }
`;
