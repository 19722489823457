import { FC, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
} from '@mui/material';
import { FormikErrors } from 'formik';

export enum ECustomSelectPredefinedOptionValues {
  notSet = '-1',
}

export interface CustomSelectProps {
  optionValues: (number | string)[];
  optionTexts: string[];
  label: string;
  notSelectedOptionText?: string;
  value?: string | number;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  sx?: SxProps;
  sxSelectMenu?: SxProps;
  labelSize?: 'small' | 'normal';
  selectSize?: 'small' | 'medium';
}

export const CustomSelect: FC<CustomSelectProps> = ({
  optionValues: optionValuesProps,
  optionTexts: optionTextsProps,
  label: inputLabel,
  notSelectedOptionText = 'Не задана',
  value: valueProp,
  onChange: onChangeProps,
  disabled,
  sx,
  error,
  helperText,
  sxSelectMenu,
  labelSize = 'small',
  selectSize = 'small',
}) => {
  const [optionValues, setOptionValues] = useState<(string | number)[]>([]);
  const [optionTexts, setOptionTexts] = useState<string[]>([]);
  const [value, setValue] = useState<string | number>();

  useEffect(() => {
    setOptionValues([ECustomSelectPredefinedOptionValues.notSet, ...optionValuesProps]);
  }, [optionValuesProps]);

  useEffect(() => {
    setOptionTexts([notSelectedOptionText, ...optionTextsProps]);
  }, [notSelectedOptionText, optionTextsProps]);

  useEffect(() => {
    let nextValue = valueProp;
    if (nextValue === null || nextValue === undefined) {
      nextValue = ECustomSelectPredefinedOptionValues.notSet;
    }
    setValue(nextValue as number);
  }, [valueProp]);

  // Описание: иногда MUI компонент Select не обновляет текущее значение,
  // когда поле value поменялось. Код ниже исправляет эту проблему.
  const selectKey: number = useMemo(() => {
    return Math.random();
  }, [value]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    let value = event.target.value;
    if (value === ECustomSelectPredefinedOptionValues.notSet) {
      value = null;
    }
    onChangeProps?.(value);
  };

  return (
    <FormControl fullWidth disabled={disabled} sx={{ flexGrow: 1, flexBasis: 0, ...sx }}>
      <InputLabel id="custom-select-label" size={labelSize} error={error}>
        {inputLabel}
      </InputLabel>
      <Select
        key={selectKey}
        labelId="custom-select-label"
        id="custom-select"
        value={value?.toString()}
        label={inputLabel}
        onChange={handleSelectChange}
        size={selectSize}
        disabled={disabled}
        error={error}
        MenuProps={{
          PaperProps: {
            sx: { ...sxSelectMenu },
          },
        }}
      >
        {optionValues.map((optionValue, index) => {
          return (
            <MenuItem key={index} value={optionValue?.toString()} sx={{ height: '36px' }}>
              {optionTexts[index]}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText error={error}>{helperText as string}</FormHelperText>}
    </FormControl>
  );
};
