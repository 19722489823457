import styled, { css } from 'styled-components';

export const MenuButtonWrapper = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 20px 40px 20px 40px;

  &.active {
    background-color: ${({ theme }) => theme.colors.dispatchersWorkplace.sidebarMenu.active};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.dispatchersWorkplace.sidebarMenu.hover};
  }

  &.active:hover {
    background-color: ${({ theme }) =>
      theme.colors.dispatchersWorkplace.sidebarMenu.activeWithHover};
  }

  @media (max-width: 1700px) {
    padding: 15px 25px 15px 25px;
  }
`;
