import { FC, useRef } from 'react';
import {
  CopyIcon,
  CopyTextButton,
  CustomTextFieldWithStyles,
  CustomTextFieldWrapper,
} from './elements';
import { SxProps, TextFieldProps, TextFieldVariants, Theme } from '@mui/material';

import { copyToClipboard } from '@/shared/utils';

export interface ICustomTextFieldOwnProps {
  displayCopyBtn?: boolean;
}

export type TCustomTextFieldProps = ICustomTextFieldOwnProps & {
  variant?: TextFieldVariants;
  sx?: SxProps<Theme> & {
    mt?: number | string;
    mb?: number | string;
    ml?: number | string;
    mr?: number | string;
  };
} & Omit<TextFieldProps, 'variant' | 'sx'>;

export const CustomTextField: FC<TCustomTextFieldProps> = ({ displayCopyBtn, sx, ...props }) => {
  const textFieldRef = useRef(null);

  const handleTextCopyBtnClick = () => {
    copyToClipboard(textFieldRef.current?.querySelector('input')?.value || '');
  };

  const { mb, mt, mr, ml, ...sxRest } = sx;

  return (
    <CustomTextFieldWrapper sx={{ mb, mt, mr, ml }}>
      <CustomTextFieldWithStyles
        {...props}
        sx={{ mr: displayCopyBtn ? '10px !important' : null, mb: 0, width: '100%', ...sxRest }}
        ref={textFieldRef}
      />
      {displayCopyBtn ? (
        <CopyTextButton
          // @ts-expect-error: Unreachable code error
          color="lightGrey"
          variant="outlined"
          component="label"
          onClick={handleTextCopyBtnClick}
        >
          <CopyIcon>content_copy</CopyIcon>
        </CopyTextButton>
      ) : null}
    </CustomTextFieldWrapper>
  );
};
