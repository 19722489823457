export type TTimerState = 'red' | 'orange' | 'green' | 'neutral';

export interface IOrderCollectors {
  amount: number;
  shift_state: 'active' | 'inactive';
}

export interface IAmountAndTime {
  amount: number;
  time: string;
  timer_state: TTimerState;
}

export interface ITime {
  time: string;
  timer_state: TTimerState;
}

export interface ISalepointInfoListItem {
  id: number;
  address: string;
  order_acceptance_state: 'enabled' | 'disabled';
  order_collectors: IOrderCollectors;
  preorders_amount: number;
  awaiting_assembly_orders: IAmountAndTime;
  orders_amount_in_assembly: number;
  assembled_orders: IAmountAndTime;
  orders_amount_in_delivery: number;
  queue_awaiting_time: ITime;
}

export interface IFetchSalepointInfoListData {
  prev_cursor?: number;
  next_cursor?: number;
  salepoint_info_list: ISalepointInfoListItem[];
}

export interface IFetchSalepointInfoListResponse {
  data: IFetchSalepointInfoListData;
}

export interface ISalepointInfoFilterItem {
  id: number;
  title: string;
}

export interface IFetchSalepointInfoFilterListResponse {
  data: {
    filters: ISalepointInfoFilterItem[];
  };
}

export interface ISalepointInfoShopListItem {
  id: number;
  address: string;
}

export interface ISalepointInfoShopGroupListItem {
  city: string;
  shops: ISalepointInfoShopListItem[];
}

export interface IFetchSalepointInfoShopListResponse {
  data: {
    shop_group_list: ISalepointInfoShopGroupListItem[];
  };
}

export type TSalepointInfoListSorting =
  | 'order_acceptance_state'
  | 'order_collectors_amount'
  | 'preorders_amount'
  | 'awaiting_assembly_orders_amount'
  | 'orders_amount_in_assembly'
  | 'assembled_orders_amount'
  | 'orders_amount_in_delivery'
  | 'queue_awaiting_time';

export type TSalepointInfoListSortingOrder = 'asc' | 'desc' | 'enabled_first' | 'disabled_first';

export enum EFetchSalepointInfoCursor {
  prevCursor = 'prev_cursor',
  nextCursor = 'next_cursor',
}

export enum ESlalepointInfoListTableColumnArrow {
  none = 'none',
  up = 'up',
  down = 'down',
}

export interface ISalepointCollectorListItem {
  full_name: string;
}

export interface IFetchSalepointCollectorListResponse {
  data: {
    salepoint_collector_list: ISalepointCollectorListItem[];
  };
}
