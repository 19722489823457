import styled from 'styled-components';

export const SalepointInfoListTableWrapper = styled.div`
  display: flex;
  margin-top: 165px;

  @media (max-width: 1700px) {
    margin-top: 134px;
  }
`;
