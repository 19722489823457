import { useCallback, useEffect, useLayoutEffect, useRef, useState, WheelEvent } from 'react';
import throttle from 'lodash.throttle';
import { useSelector } from 'react-redux';
import { CircularProgress, SxProps } from '@mui/material';

import {
  TableBodyAddressCell,
  TableBodyCell,
  TableBodyElement,
  TableBodyRow,
  TableCellText,
  TableElement,
  TableHeadRow,
} from './elements';
import { TRootState, useAppDispatch } from '@/store';
import {
  EFetchSalepointInfoCursor,
  ESlalepointInfoListTableColumnArrow,
  ISalepointInfoListItem,
  TSalepointInfoListSorting,
} from '../../../types';
import { OrderAcceptanceState } from './components/OrderAcceptanceState';
import { OrderCollectors } from './components/OrderCollectors';
import { AmountAndTime } from './components/AmountAndTime';
import { TimeWithIcon } from './components/TimeWithIcon';
import { TableHeadCell } from './components/TableHeadCell';
import {
  addSalepointInfoListNextCursor,
  setSelectedSorting,
  setTableColumnArrow,
} from '../../../redux/salepointInfoListDashboard.slice';
import { ERequestStatus } from '@/shared/lib/types';
import {
  fetchSalepointCollectorList,
  fetchSalepointInfoListData,
} from '../../../redux/salepointInfoListDashboard.actions';
import { usePrevious } from '@/shared/hooks/usePrevious';
import { OrderCollectorsAmountWithShopCollectorListPopover } from './components/OrderCollectorsAmountWithShopCollectorListPopover';

interface ISalepointInfoListTableCellItem {
  title?: string;
  isOneLineText?: boolean;
  sorting?: TSalepointInfoListSorting;
  sxHeadingCell?: SxProps;
  sxBodyCell: SxProps;
}

const salepointInfoListTableCells: ISalepointInfoListTableCellItem[] = [
  {
    sxHeadingCell: {
      minWidth: '66px',
      width: '66px',
      '@media (max-width: 1700px)': { minWidth: '50px', width: '50px' },
    },
    sxBodyCell: {
      minWidth: '66px',
      width: '66px',
      '@media (max-width: 1700px)': { minWidth: '50px', width: '50px' },
    },
  },
  {
    sxHeadingCell: {
      minWidth: '419px',
      width: '25%',
      '@media (max-width: 1700px)': { minWidth: '324px', width: '18.75%' },
    },
    sxBodyCell: {
      minWidth: '419px',
      width: '25%',
      '@media (max-width: 1700px)': { minWidth: '324px', width: '18.75%' },
    },
  },
  {
    title: 'Приём заказов',
    isOneLineText: false,
    sorting: 'order_acceptance_state',
    sxHeadingCell: {
      minWidth: '105px',
      width: '105px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '105px',
      width: '105px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'Активные сборщики',
    isOneLineText: false,
    sorting: 'order_collectors_amount',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'Предзаказ',
    isOneLineText: false,
    sorting: 'preorders_amount',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'Ожидают сборку',
    isOneLineText: false,
    sorting: 'awaiting_assembly_orders_amount',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'В сборке',
    isOneLineText: true,
    sorting: 'orders_amount_in_assembly',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'Собран',
    isOneLineText: false,
    sorting: 'assembled_orders_amount',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'Доставляется',
    isOneLineText: false,
    sorting: 'orders_amount_in_delivery',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
  {
    title: 'Очередь сборки',
    isOneLineText: false,
    sorting: 'queue_awaiting_time',
    sxHeadingCell: {
      minWidth: '100px',
      width: '100px',
      cursor: 'pointer',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
    sxBodyCell: {
      minWidth: '100px',
      width: '100px',
      '@media (max-width: 1700px)': { minWidth: '90px', width: '90px' },
    },
  },
];

const SalepointInfoListTable = () => {
  const dispatch = useAppDispatch();
  const selectedSorting = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.selectedSorting
  );
  const tableColumnArrow = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.tableColumnArrow
  );
  const { next_cursor: nextCursor } = useSelector(
    (state: TRootState) =>
      state.dispatchersWorkplace.salepointInfoListDashboard.salepointInfoListData || {}
  );
  const salepointInfoList = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.salepointInfoList
  );
  const fetchSalepointInfoListDataStatus = useSelector(
    (state: TRootState) =>
      state.dispatchersWorkplace.salepointInfoListDashboard.fetchSalepointInfoListDataStatus
  );
  const tableBodyElementRef = useRef<HTMLDivElement>();
  const tableBodyContentWrapperRef = useRef<HTMLDivElement>();
  const [plannedFetchSalepointInfoListId, setPlannedFetchSalepointInfoListId] = useState(0);
  const prevPlannedFetchSalepointInfoListId = usePrevious(plannedFetchSalepointInfoListId);
  const [prevTableBodyElementScrollTop, setPrevTableBodyElementScrollTop] = useState(0);
  const [currentShopNumberForOrderCollectorAmount, setCurrentShopNumberForOrderCollectorAmount] =
    useState<number | null>(null);

  const handleTableHeadCellClick = (sorting: TSalepointInfoListSorting | '') => {
    if (selectedSorting === sorting) {
      if (tableColumnArrow === ESlalepointInfoListTableColumnArrow.none) {
        dispatch(setTableColumnArrow(ESlalepointInfoListTableColumnArrow.up));
      } else if (tableColumnArrow === ESlalepointInfoListTableColumnArrow.up) {
        dispatch(setTableColumnArrow(ESlalepointInfoListTableColumnArrow.down));
      } else {
        dispatch(setTableColumnArrow(ESlalepointInfoListTableColumnArrow.none));
      }
    } else {
      dispatch(setTableColumnArrow(ESlalepointInfoListTableColumnArrow.up));
      dispatch(setSelectedSorting(sorting));
    }
  };

  const handleTableBodyElementScrollThrottled = useCallback(
    throttle(({ prevTableBodyElementScrollTop }: { prevTableBodyElementScrollTop?: number }) => {
      if (tableBodyElementRef.current) {
        setPrevTableBodyElementScrollTop(tableBodyElementRef.current.scrollTop);
        if (tableBodyElementRef.current.scrollTop > prevTableBodyElementScrollTop) {
          setPlannedFetchSalepointInfoListId(Date.now());
        }
      }
    }, 500),
    []
  );

  const handleTableBodyElementWheelThrottled = useCallback(
    throttle(({ event }: { event?: WheelEvent<HTMLDivElement> }) => {
      function checkWheelDirectionIsDown(event: WheelEvent) {
        if ((event as any).wheelDelta) {
          return (event as any).wheelDelta < 0;
        }
        return event.deltaY > 0;
      }

      if (tableBodyElementRef.current && tableBodyElementRef.current.scrollTop > 0) {
        if (checkWheelDirectionIsDown(event)) {
          setPlannedFetchSalepointInfoListId(Date.now());
        }
      }
    }, 500),
    []
  );

  useLayoutEffect(() => {
    if (
      tableBodyElementRef.current &&
      tableBodyContentWrapperRef.current &&
      plannedFetchSalepointInfoListId !== prevPlannedFetchSalepointInfoListId &&
      nextCursor &&
      fetchSalepointInfoListDataStatus !== ERequestStatus.pending
    ) {
      const tableBodyElementScrollTop = tableBodyElementRef.current.scrollTop;
      const { height: tableBodyElementHeight } =
        tableBodyElementRef.current.getBoundingClientRect();
      const { height: tableBodyContentWrapperHeight } =
        tableBodyContentWrapperRef.current.getBoundingClientRect();

      const isNeedFetchData =
        tableBodyElementScrollTop > tableBodyContentWrapperHeight - tableBodyElementHeight * 2;
      if (isNeedFetchData) {
        dispatch(addSalepointInfoListNextCursor(nextCursor));
        dispatch(fetchSalepointInfoListData({ cursor: EFetchSalepointInfoCursor.nextCursor }));
      }
    }
  }, [
    fetchSalepointInfoListDataStatus,
    nextCursor,
    plannedFetchSalepointInfoListId,
    prevPlannedFetchSalepointInfoListId,
  ]);

  const handleOrderCollectorsAmountClick = (id: number) => {
    setCurrentShopNumberForOrderCollectorAmount(id);
  };

  useEffect(() => {
    let fetchSalepointCollectorListPromise: any;
    if (currentShopNumberForOrderCollectorAmount !== null) {
      fetchSalepointCollectorListPromise = dispatch(
        fetchSalepointCollectorList({ shopNumber: currentShopNumberForOrderCollectorAmount })
      );
    }
    return () => {
      fetchSalepointCollectorListPromise?.abort();
    };
  }, [dispatch, currentShopNumberForOrderCollectorAmount]);

  const renderSalepointInfoTableCells = (salepointInfoListItem: ISalepointInfoListItem) => {
    const {
      id: shopNumber,
      address,
      order_acceptance_state,
      order_collectors,
      preorders_amount,
      awaiting_assembly_orders,
      orders_amount_in_assembly,
      assembled_orders,
      orders_amount_in_delivery,
      queue_awaiting_time,
    } = salepointInfoListItem;
    return (
      <>
        <TableBodyCell sx={salepointInfoListTableCells[0].sxBodyCell}>
          <TableCellText>{shopNumber}</TableCellText>
        </TableBodyCell>
        <TableBodyAddressCell sx={salepointInfoListTableCells[1].sxBodyCell}>
          <TableCellText>{address}</TableCellText>
        </TableBodyAddressCell>
        <TableBodyCell sx={salepointInfoListTableCells[2].sxBodyCell}>
          <OrderAcceptanceState state={order_acceptance_state} />
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[3].sxBodyCell}>
          <OrderCollectorsAmountWithShopCollectorListPopover
            shopNumber={shopNumber}
            orderCollectors={order_collectors}
            onClick={handleOrderCollectorsAmountClick}
          />
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[4].sxBodyCell}>
          <TableCellText>{preorders_amount}</TableCellText>
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[5].sxBodyCell}>
          <AmountAndTime amountAndTime={awaiting_assembly_orders} />
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[6].sxBodyCell}>
          <TableCellText>{orders_amount_in_assembly}</TableCellText>
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[7].sxBodyCell}>
          <AmountAndTime amountAndTime={assembled_orders} />
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[8].sxBodyCell}>
          <TableCellText>{orders_amount_in_delivery}</TableCellText>
        </TableBodyCell>
        <TableBodyCell sx={salepointInfoListTableCells[9].sxBodyCell}>
          <TimeWithIcon time={queue_awaiting_time}></TimeWithIcon>
        </TableBodyCell>
      </>
    );
  };

  return (
    <TableElement>
      <div>
        <TableHeadRow>
          {salepointInfoListTableCells.map((tableHeading, index) => {
            const { title, isOneLineText, sorting, sxHeadingCell } = tableHeading;
            const isSelected = sorting === selectedSorting;

            if (index < 2) {
              return <TableHeadCell key={index} sx={sxHeadingCell} />;
            }

            return (
              <TableHeadCell
                key={index}
                arrowDirection={
                  (isSelected && tableColumnArrow) || ESlalepointInfoListTableColumnArrow.none
                }
                title={title}
                isOneLineText={isOneLineText}
                onClick={() => handleTableHeadCellClick(sorting)}
                sx={sxHeadingCell}
              />
            );
          })}
        </TableHeadRow>
      </div>
      <TableBodyElement
        ref={tableBodyElementRef}
        onScroll={() =>
          handleTableBodyElementScrollThrottled({
            prevTableBodyElementScrollTop,
          })
        }
        onWheel={(event) => handleTableBodyElementWheelThrottled({ event })}
      >
        <div ref={tableBodyContentWrapperRef}>
          {salepointInfoList?.map((salepointInfoRow) => {
            return (
              <TableBodyRow key={salepointInfoRow.id}>
                {renderSalepointInfoTableCells(salepointInfoRow)}
              </TableBodyRow>
            );
          })}
          <TableBodyRow
            $isVisble={fetchSalepointInfoListDataStatus === ERequestStatus.pending}
            sx={{ borderBottom: 'none', justifyContent: 'center' }}
          >
            <TableBodyCell sx={{ padding: '10px' }}>
              <CircularProgress sx={{ color: '#2889ce' }} />
            </TableBodyCell>
          </TableBodyRow>
        </div>
      </TableBodyElement>
    </TableElement>
  );
};

export default SalepointInfoListTable;
