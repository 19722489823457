import {
  EPromotionActionBtnType,
  EPromotionButtonParamsName,
  EPromotionButtonParamsType,
  EPromotionCollectionStatusKey,
  EPromotionCollectionStatusName,
  EPromotionStatusKey,
  EPromotionStatusName,
  EPromotionType,
  IPromotionCollectionItem,
  IPromotionItem,
  IPromotionVisualizationData,
} from '../types';

export const promotionTypeOptionValues = [
  EPromotionType.bonuses,
  EPromotionType.discount,
  EPromotionType.bonusesPlusDiscount,
  EPromotionType.chips,
  EPromotionType.present,
  EPromotionType.delivery,
  EPromotionType.fromPartner,
];

export const promotionTypeOptionTexts = [
  'Бонусы',
  'Скидка',
  'Бонусы + Скидка',
  'Фишки',
  'Подарок',
  'Доставка',
  'От партнера',
];

export const promotionSatuses = [
  {
    key: EPromotionStatusKey.draft,
    name: EPromotionStatusName.draft,
  },
  {
    key: EPromotionStatusKey.activated,
    name: EPromotionStatusName.activated,
  },
  {
    key: EPromotionStatusKey.reactivated,
    name: EPromotionStatusName.reactivated,
  },
  {
    key: EPromotionStatusKey.pending,
    name: EPromotionStatusName.pending,
  },
  {
    key: EPromotionStatusKey.published,
    name: EPromotionStatusName.published,
  },
  {
    key: EPromotionStatusKey.suspended,
    name: EPromotionStatusName.suspended,
  },
  {
    key: EPromotionStatusKey.finished,
    name: EPromotionStatusName.finished,
  },
];

export const promotionSatusOptionValues = [
  EPromotionStatusKey.draft,
  EPromotionStatusKey.activated,
  EPromotionStatusKey.reactivated,
  EPromotionStatusKey.pending,
  EPromotionStatusKey.published,
  EPromotionStatusKey.suspended,
  EPromotionStatusKey.finished,
];

export const promotionStatusOptionTexts = [
  EPromotionStatusName.draft,
  EPromotionStatusName.activated,
  EPromotionStatusName.reactivated,
  EPromotionStatusName.pending,
  EPromotionStatusName.published,
  EPromotionStatusName.suspended,
  EPromotionStatusName.finished,
];

export const promotionBtnActionTypeOptionValues = [
  EPromotionActionBtnType.close,
  EPromotionActionBtnType.deepLink,
  EPromotionActionBtnType.webView,
  EPromotionActionBtnType.browser,
  EPromotionActionBtnType.share,
];

export const promotionBtnActionTypeOptionTexts = [
  'Закрыть',
  'Диплинк',
  'Веб вью',
  'Браузер',
  'Шэар',
];

export const promotionBtnNameOptionValues = [EPromotionButtonParamsName.footer];

export const promotionBtnNameOptionTexts = ['Футер'];

export const promotionBtnTypeOptionValues = [EPromotionButtonParamsType.button];

export const promotionBtnTypeOptionTexts = ['Кнопка'];

export const newPromotion: IPromotionItem = {
  name: '',
  type: null,
  categories: [],
  status: null,
  list_image: '',
  full_image: '',
  description: '',
  condition: '',
  requirements: '',
  button: {
    params: {
      name: EPromotionButtonParamsName.footer,
      type: EPromotionButtonParamsType.button,
      value: '',
      background_color: '#FFFFFF',
      text_color: '#000000',
      on_click: {
        action_type: '',
        action_value: '',
      },
    },
  },
  public_datetime: null,
  start_datetime: null,
  finish_datetime: null,
};

export const newPromotionCollection: IPromotionCollectionItem = {
  name: '',
  status: {
    key: '',
    name: '',
  },
  image_url: '',
  title_url: '',
  created_at: null,
  promotions: [],
};

export const promotionCollectionStatusNames: Record<string, string> = {
  [EPromotionCollectionStatusKey.draft]: EPromotionCollectionStatusName.draft,
  [EPromotionCollectionStatusKey.ready]: EPromotionCollectionStatusName.ready,
  [EPromotionCollectionStatusKey.published]: EPromotionCollectionStatusName.published,
  [EPromotionCollectionStatusKey.archived]: EPromotionCollectionStatusName.archived,
};

export const promotionVisualizationDataEmpty: IPromotionVisualizationData = {
  short_promotion: null,
  full_promotion: null,
};
