import styled from 'styled-components';

export const DelimiterWrapper = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  user-select: none;
  padding: 20px 40px 20px 40px;

  @media (max-width: 1700px) {
    padding: 15px 25px 15px 25px;
  }
`;
