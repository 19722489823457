import { Box } from '@mui/material';
import styled from 'styled-components';

export const TableBodyElement = styled(Box)`
  height: calc(100vh - 254px);
  overflow-y: auto;

  @media (max-width: 1700px) {
    height: calc(100vh - 216px);
  }
`;
