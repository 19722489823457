import { ESlalepointInfoListTableColumnArrow, TSalepointInfoListSorting } from '../types';

interface IGetSalepointInfoListTableSortingDirectionProps {
  selectedSorting: TSalepointInfoListSorting;
  tableColumnArrow: ESlalepointInfoListTableColumnArrow;
}

export const getSalepointInfoListTableSortingDirection = ({
  selectedSorting,
  tableColumnArrow,
}: IGetSalepointInfoListTableSortingDirectionProps) => {
  if (selectedSorting === 'order_acceptance_state') {
    if (tableColumnArrow === ESlalepointInfoListTableColumnArrow.up) {
      return 'disabled_first';
    } else if (tableColumnArrow === ESlalepointInfoListTableColumnArrow.down) {
      return 'enabled_first';
    }
  } else {
    if (tableColumnArrow === ESlalepointInfoListTableColumnArrow.up) {
      return 'asc';
    } else if (tableColumnArrow === ESlalepointInfoListTableColumnArrow.down) {
      return 'desc';
    }
  }
};
