import styled from 'styled-components';

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;

  @media (max-width: 1700px) {
    width: 16px;
    height: 21px;
  }
`;
