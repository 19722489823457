import { Box } from '@mui/material';
import styled from 'styled-components';

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 400px;
  background-color: #2b2a33;
  border-radius: 5px;
`;
