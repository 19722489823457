import cloneDeep from 'lodash.clonedeep';

import { ISlide, IStory } from '../types';

export const tranformStoryBeforeSave = (activeStory: IStory) => {
  let resultStory;
  resultStory = cloneDeep(activeStory);

  if (activeStory.isNewStory) {
    if (resultStory.isNewStory) {
      const { id, isNewStory, isSavedStory, ...storyRest } = resultStory;
      resultStory = storyRest;
    }
  }

  resultStory.slides = resultStory.slides.map((slide: ISlide, index) => {
    if (slide.isNewSlide) {
      const { isNewSlide, ...slideRest } = slide;
      if (activeStory.isNewStory) {
        return { version: 1, ...slideRest, number: index + 1 };
      } else {
        return { version: 1, story_id: activeStory.id, ...slideRest, number: index + 1 };
      }
    } else {
      return { ...slide, number: index + 1 };
    }
  });

  return { data: { story: resultStory } };
};
