import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LogoLink = styled(Link)`
  display: block;
  width: 180px;
  padding: 10px;
  text-decoration: none;
  user-select: none;

  @media (max-width: 1700px) {
    width: 153px;
  }
`;
