/// <reference types="vite-plugin-svgr/client" />
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { useMatch, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import {
  DispatcherName,
  EmptySpace,
  LogoLink,
  LogoWrapper,
  MenuButtonLink,
  RetailNetworkLogo,
} from './elements';
import { SidebarContent } from './elements';
import { SidebarWrapper } from './elements/SidebarWrapper';
import { bottomMenuItems, exitMenuItem, topMenuItems } from './const';
import { MenuButton } from './components/MenuButton';
import { TRootState } from '@/store';
import { fetchDispatchersWorkplaceBasicInfo } from './redux/dispatchersWorkplaceSidebar.actions';
import { useAppDispatch } from '@/store';
import { DispatcherJobTitleAndRetailNetworkTitle } from './elements/DispatcherJobTitleAndRetailNetworkTitle';
import { Delimiter } from './components/Delimiter';
import { ROUTES } from '@/shared/lib/const';

export const DispatchersWorplaceSidebarMenu = () => {
  const ExitIcon = exitMenuItem.icon;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const basicInfo = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.sidebar.basicInfo
  );
  const match = useMatch('/service/internet-shop/module/dispatchers-workplace/page/:page');
  const [page, setPage] = useState('');

  useEffect(() => {
    if (match) {
      const { page } = match.params;
      if (page) {
        setPage(page);
      }
    }
  }, [match]);

  useEffect(() => {
    dispatch(fetchDispatchersWorkplaceBasicInfo());
  }, [dispatch]);

  const handleExitBtnClick = () => {
    const domain = document.location.hostname;
    Cookies.remove('session_id', { path: '/' });
    Cookies.remove('userId', { path: '/' });
    if (domain !== 'localhost') {
      const consoleSubdomain = domain.replace(/^[^.]+\./, '.');
      Cookies.remove('session_id', { path: '/', domain: consoleSubdomain });
      Cookies.remove('userId', { path: '/', domain: consoleSubdomain });
    }
    navigate(ROUTES.login);
  };

  return (
    <SidebarWrapper $withGradient>
      <Scrollbars>
        <SidebarContent>
          <LogoWrapper>
            <LogoLink
              to={`/service/internet-shop/module/dispatchers-workplace/${topMenuItems[0].link}`}
            >
              <RetailNetworkLogo $img={basicInfo?.logo_url} />
            </LogoLink>
          </LogoWrapper>
          <DispatcherName>{basicInfo?.dispatcher?.name}</DispatcherName>
          <DispatcherJobTitleAndRetailNetworkTitle>
            {basicInfo?.dispatcher?.job_title} {basicInfo?.retail_network_title}
          </DispatcherJobTitleAndRetailNetworkTitle>

          {topMenuItems.map((menuItem, index) => {
            const Icon = menuItem.icon;
            return (
              <MenuButtonLink key={index} to={menuItem.link}>
                <MenuButton $isActive={page === menuItem.page} Icon={Icon} text={menuItem.title} />
              </MenuButtonLink>
            );
          })}
          <Delimiter />
          {bottomMenuItems.map((menuItem, index) => {
            const Icon = menuItem.icon;
            return (
              <MenuButtonLink key={index} to={menuItem.link}>
                <MenuButton $isActive={page === menuItem.page} Icon={Icon} text={menuItem.title} />
              </MenuButtonLink>
            );
          })}
          <EmptySpace />

          <MenuButton onClick={handleExitBtnClick} Icon={ExitIcon} text={exitMenuItem.title} />
        </SidebarContent>
      </Scrollbars>
    </SidebarWrapper>
  );
};
