/// <reference types="vite-plugin-svgr/client" />

import { FC } from 'react';
import cn from 'classnames';

import { MenuButtonProps } from './MenuButtonProps';
import { IconWrapper, MenuButtonWrapper, Text } from './elements';

export const MenuButton: FC<MenuButtonProps> = ({ Icon, text, $isActive, ...rest }) => {
  return (
    <MenuButtonWrapper className={cn({ active: $isActive })} {...rest}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Text>{text}</Text>
    </MenuButtonWrapper>
  );
};
