import { createSlice } from '@reduxjs/toolkit';

import { ERequestStatus } from '@/shared/lib/types';
import { IDispatchersWorkplaceBasicInfo } from '../types';
import { dispatchersWorkplaceBasicInfo } from '../stub/dispatchersWorkplaceBasicInfoData';
import { fetchDispatchersWorkplaceBasicInfo } from './dispatchersWorkplaceSidebar.actions';

interface IDispatchersWorkplaceSidebarState {
  basicInfo: IDispatchersWorkplaceBasicInfo;
  fetchBasicInfoStatus: ERequestStatus;
}

const initialState: IDispatchersWorkplaceSidebarState = {
  basicInfo: {} as IDispatchersWorkplaceBasicInfo, // dispatchersWorkplaceBasicInfo,
  fetchBasicInfoStatus: ERequestStatus.idle,
};

const dispatchersWorkplaceSidebar = createSlice({
  name: 'dispatchersWorkplaceSidebar',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDispatchersWorkplaceBasicInfo.pending, (state) => {
        state.fetchBasicInfoStatus = ERequestStatus.pending;
      })
      .addCase(fetchDispatchersWorkplaceBasicInfo.fulfilled, (state, action) => {
        state.fetchBasicInfoStatus = ERequestStatus.success;
        state.basicInfo = action.payload;
      })
      .addCase(fetchDispatchersWorkplaceBasicInfo.rejected, (state) => {
        state.fetchBasicInfoStatus = ERequestStatus.error;
      });
  },
});

export default dispatchersWorkplaceSidebar.reducer;
