import styled from 'styled-components';

export const OrderCollectorsAmount = styled.div<{ $shiftState: 'active' | 'inactive' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme, $shiftState }) => {
    const activeColor =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.orderCollectors.active;
    const inactiveColor =
      theme.colors.dispatchersWorkplace.salepointInfoListDashboard.orderCollectors.inactive;
    return $shiftState === 'active' ? activeColor : inactiveColor;
  }};
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) =>
    theme.colors.dispatchersWorkplace.salepointInfoListDashboard.orderCollectors.text};

  @media (max-width: 1700px) {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
`;
