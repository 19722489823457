import { EBannerFooterType, IBanner } from '../types';

export const emptyBannerData: IBanner = {
  external_id: '',
  name: '',
  title: {
    value: '',
    text_color: '#000000',
  },
  text: {
    value: '',
    text_color: '#000000',
  },
  footer: {
    type: EBannerFooterType.button,
    value: '',
    text_color: '#000000',
    background_color: '#FFFFFF',
  },
  right_image_url: '',
  deep_link: {
    type: '',
    value: '',
  },
};
