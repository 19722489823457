import { ESlideActionType, ESlideTextType, ISlide, IStory } from '../types';

export const SLIDE_VIEWPORT_DIMENSIONS_COEFF = 0.67;

export const newStoryInitial: IStory = {
  isNewStory: true,
  isSavedStory: false,
  external_story_id: '',
  name: '',
  type: 'test_type',
  preview: {
    params: [
      {
        name: 'title',
        type: ESlideTextType.text,
        value: '',
        text_color: '#000000',
      },
    ],
  },
  slides: [],
};

interface IGetNewSlideInitialProps {
  slideNumber: number;
}

export const getNewSlideInitial = ({ slideNumber }: IGetNewSlideInitialProps): ISlide => ({
  isNewSlide: true,
  external_slide_id: '',
  name: '',
  number: slideNumber,
  version: 1,
  duration: 6,
  type: 'test_type',
  slides: {
    params: [
      {
        name: 'title',
        type: ESlideTextType.header,
        value: '',
        text_color: '#000000',
      },
      {
        name: 'description',
        type: ESlideTextType.header,
        value: '',
        text_color: '#000000',
      },
      {
        name: 'footer',
        type: 'button',
        value: '',
        on_click: {
          action_type: '' as ESlideActionType,
          action_value: '',
        },
        text_color: '#000000',
        background_color: '#FFFFFF',
      },
      {
        name: 'progress',
        type: 'bar',
        progress_color: '#9B9B9B',
        background_color: '#FFFFFF',
      },
      {
        name: 'close',
        type: 'cross_button',
        on_clink: {
          action_type: 'close',
        },
        background_color: '#FFFFFF',
      },
    ],
  },
});
