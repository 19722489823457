import { FC, SyntheticEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams } from 'react-router-dom';

import {
  Banner,
  BannerNameWrapper,
  BannerName,
  StyledLink,
  TooltipImg,
  BannerCheckboxWrapper,
} from '@/features/banners/scenario/components/Card/elements';
import { useAppDispatch } from '@/store';
import { ROUTES } from '@/shared/lib/const';
import { IBannerListItem } from '@/features/banners/types';
import { setBannerIsActive } from '@/features/banners/redux/scenarios.slice';

interface ICardOwnProps {
  handleClassName?: string;
}

type TCardOwnProps = ICardOwnProps & IBannerListItem;

export const Card: FC<TCardOwnProps> = ({
  id: bannerId,
  image_link,
  name,
  is_active,
  handleClassName,
}) => {
  const dispatch = useAppDispatch();
  const { scenario: scenarioId } = useParams();

  const handleIsActiveCheckboxChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    dispatch(
      setBannerIsActive({
        bannerId,
        checked,
      })
    );
    event.preventDefault();
  };

  const handleCheckboxClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <StyledLink
      to={ROUTES.banner
        .replace(':scenario', encodeURIComponent(scenarioId))
        .replace(':banner', bannerId.toString())}
      className={handleClassName}
    >
      <Banner>
        <TooltipImg $url={image_link} />
        <BannerNameWrapper>
          <BannerName>{name}</BannerName>
        </BannerNameWrapper>
        <BannerCheckboxWrapper>
          <FormControlLabel
            control={<Checkbox />}
            checked={is_active}
            onChange={handleIsActiveCheckboxChange}
            onClick={handleCheckboxClick}
            label="Отображать"
            sx={{ marginRight: 0 }}
          />
        </BannerCheckboxWrapper>
      </Banner>
    </StyledLink>
  );
};
