import {
  FC,
  useLayoutEffect,
  useEffect,
  useState,
  useRef,
  SyntheticEvent,
  KeyboardEvent,
  ChangeEvent,
  useCallback,
} from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { SxProps, TextField, Theme } from '@mui/material';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

import TableColumnArrowDownSvg from '@/assets/icons/dispatchers-workplace/table-arrow-down.svg?react';
import TableColumnArrowUpSvg from '@/assets/icons/dispatchers-workplace/table-arrow-up.svg?react';
import {
  ISalepointInfoShopGroupListItem,
  ISalepointInfoShopListItem,
} from '@/features/dispatchers-workplace/salepoint-info-list-dashboard/types';
import {
  CloseIcon,
  CloseIconWrapper,
  EmptyListWrapper,
  MenuItemContent,
  ShopAddressSelectWrapper,
  ShopCityWrapper,
  TextFieldEmptyText,
} from './elements';

const sxPopperDefault: SxProps = {
  zIndex: '10',
  height: '224px',
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  backgroundColor: '#ffffff',
  padding: '10px 0',
};

const sxPaperDefault: SxProps = {
  height: '204px',
  overflow: 'auto',
  boxShadow: 'none',
};

const sxMenuListDefault: SxProps = {
  paddingTop: '0px',
  paddingBottom: '0px',
};

interface IShopAddressSelectProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  sxWrapper?: SxProps;
  sxPopper?: SxProps;
  sxPaper?: SxProps<Theme>;
  sxMenuList?: SxProps;
  shopGroupList: ISalepointInfoShopGroupListItem[];
  handleMenuItemClick: (shopId: number) => void;
  textFieldEmptyText?: string;
  inputSize?: 'small' | 'medium';
}

export const ShopAddressSelect: FC<IShopAddressSelectProps> = ({
  label,
  value: textFieldValueProps,
  onChange: onTextFieldChangeProps,
  sxWrapper,
  sxPopper = sxPopperDefault,
  sxPaper = sxPaperDefault,
  sxMenuList = sxMenuListDefault,
  shopGroupList = [],
  handleMenuItemClick: handleMenuItemClickProps,
  textFieldEmptyText = 'Не выбран',
  inputSize = 'medium',
}) => {
  const [open, setOpen] = useState(false);
  const shopAddressSelectWrapperRef = useRef<HTMLElement>();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [menuWidth, setMenuWidth] = useState('0px');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [shopGroupListIFiltered, setShopGroupListIFiltered] =
    useState<ISalepointInfoShopGroupListItem[]>();
  const textFieldRef = useRef<HTMLInputElement>();
  const closeIconWrapperRef = useRef<HTMLDivElement>();
  const [clearTextButtonShown, setClearTextButtonShown] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const isShowClearButtonBlocked = useRef(false);
  const showClearButtonBlockedTimeout = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    if (textFieldRef.current && textFieldRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    if (
      closeIconWrapperRef.current &&
      closeIconWrapperRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: Event | KeyboardEvent<HTMLElement>) => {
    const key = (event as KeyboardEvent<HTMLElement>).key;
    if (key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (key === 'Escape') {
      setOpen(false);
    }
  };

  const handleShopSelect = (shopId: number) => {
    let foundShop: ISalepointInfoShopListItem;
    shopGroupList.find((shopGroup) => {
      return shopGroup?.shops.find((shop) => {
        if (shopId === shop.id) {
          foundShop = shop;
        }
        return shopId === shop.id;
      });
    });

    setTextFieldValue(foundShop.address);
    filterShopGroupListDebounced(foundShop.address);
  };

  const handleMenuItemClick = ({
    event,
    shopId,
  }: {
    event: Event | SyntheticEvent;
    shopId: ISalepointInfoShopListItem['id'];
  }) => {
    handleClose(event);
    handleMenuItemClickProps(shopId);
    handleShopSelect(shopId);
  };

  const renderShopGroup = (shopGroup: ISalepointInfoShopGroupListItem) => {
    return (
      <>
        <ShopCityWrapper>{shopGroup.city}</ShopCityWrapper>
        {shopGroup?.shops.map((shop) => {
          return (
            <MenuItem
              key={shop.id}
              onClick={(event) => handleMenuItemClick({ event, shopId: shop.id })}
            >
              <MenuItemContent sx={{ color: 'rgba(0, 0, 0, 0.7)' }}>{shop.address}</MenuItemContent>
            </MenuItem>
          );
        })}
      </>
    );
  };

  const renderEmptyList = () => {
    let displayedText;
    if (shopGroupListIFiltered.length === 0 && shopGroupList.length > 0) {
      displayedText = 'Данный адрес не найден';
    } else {
      displayedText = 'В списке нет элементов';
    }
    return <EmptyListWrapper>{displayedText}</EmptyListWrapper>;
  };

  const filterShopGroupListDebounced = useCallback(
    debounce((address: string) => {
      const filteredList: ISalepointInfoShopGroupListItem[] = [];
      shopGroupList.forEach((shopGroup) => {
        const shopGroupFiltered: ISalepointInfoShopGroupListItem = {
          city: shopGroup.city,
          shops: [],
        };
        shopGroup.shops.forEach((shop) => {
          if (shop.address.toLocaleLowerCase().includes(address.toLocaleLowerCase())) {
            shopGroupFiltered.shops.push(shop);
          }
        });
        if (shopGroupFiltered.shops.length > 0) {
          filteredList.push(shopGroupFiltered);
        }
      });
      setShopGroupListIFiltered(filteredList);
    }, 300),
    [shopGroupList]
  );

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTextFieldValue(value);
    setOpen(true);
    filterShopGroupListDebounced(value);
    if (value) {
      setClearTextButtonShown(true);
    } else {
      setClearTextButtonShown(false);
    }
  };

  const handleTextFieldKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onTextFieldChangeProps(textFieldValue);
      setOpen(false);
    }
  };

  const handleTextFieldMouseMoveThrottled = useCallback(
    throttle(() => {
      if (textFieldValue && !isShowClearButtonBlocked.current) {
        setClearTextButtonShown(true);
      }
    }, 300),
    [textFieldValue]
  );

  const handleTextFieldMouseLeaveThrottled = useCallback(
    throttle(() => {
      if (!isFocused) {
        clearTimeout(showClearButtonBlockedTimeout.current);
        setClearTextButtonShown(false);
        isShowClearButtonBlocked.current = true;
        showClearButtonBlockedTimeout.current = setTimeout(() => {
          isShowClearButtonBlocked.current = false;
        }, 350);
      }
    }, 300),
    [isFocused, textFieldValue]
  );

  const handleTextFieldFocus = () => {
    setIsFocused(true);
    if (textFieldValue) {
      setClearTextButtonShown(true);
    }
  };

  const handleTextFieldBlur = () => {
    setIsFocused(false);
    setClearTextButtonShown(false);
  };

  const handleClearTextBtnClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.querySelector('input')?.focus();
    }
    setTextFieldValue('');
    onTextFieldChangeProps('');
    setClearTextButtonShown(false);
    setShopGroupListIFiltered(shopGroupList);
  };

  useEffect(() => {
    setShopGroupListIFiltered(shopGroupList);
  }, [shopGroupList]);

  useEffect(() => {
    window.addEventListener('keydown', handleListKeyDown);

    return () => window.removeEventListener('keydown', handleListKeyDown);
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useLayoutEffect(() => {
    if (shopAddressSelectWrapperRef.current) {
      const element = shopAddressSelectWrapperRef.current;
      const rect = element.getBoundingClientRect();
      const { width } = rect;
      setMenuWidth(`${width}px`);
    }
  }, []);

  return (
    <ShopAddressSelectWrapper
      sx={sxWrapper}
      ref={shopAddressSelectWrapperRef}
      onMouseMove={handleTextFieldMouseMoveThrottled}
      onMouseLeave={handleTextFieldMouseLeaveThrottled}
    >
      <TextField
        ref={textFieldRef}
        label={label}
        value={textFieldValue}
        onChange={handleTextFieldChange}
        onKeyUp={handleTextFieldKeyUp}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
              paddingRight: '93px',
            },
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onFocus={handleTextFieldFocus}
        onBlur={handleTextFieldBlur}
        size={inputSize}
      />

      {!isFocused && !textFieldValue && (
        <TextFieldEmptyText>{textFieldEmptyText}</TextFieldEmptyText>
      )}

      <CloseIconWrapper
        ref={closeIconWrapperRef}
        $clearTextButtonShown={clearTextButtonShown}
        onClick={handleClearTextBtnClick}
      >
        <CloseIcon>close</CloseIcon>
      </CloseIconWrapper>

      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          height: '100%',
          '& .MuiTouchRipple-childPulsate': {
            display: 'none !important',
          },
        }}
      >
        {open ? <TableColumnArrowUpSvg /> : <TableColumnArrowDownSvg />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        sx={{ ...sxPopper, width: menuWidth }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper sx={sxPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={sxMenuList}
                >
                  {shopGroupListIFiltered && shopGroupListIFiltered.length > 0
                    ? shopGroupListIFiltered.map((shopGroup, index) => {
                        return <div key={index}>{renderShopGroup(shopGroup)}</div>;
                      })
                    : renderEmptyList()}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ShopAddressSelectWrapper>
  );
};
