import styled from 'styled-components';

export const DispatcherJobTitleAndRetailNetworkTitle = styled.div`
  margin: 0 0 40px 40px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) =>
    theme.colors.dispatchersWorkplace.sidebarMenu.dispatcherJobTitleAndRetailNetworkTitle};

  @media (max-width: 1700px) {
    margin: 0 0 40px 25px;
  }
`;
