import styled, { css } from 'styled-components';

export const Slide = styled.div<{
  $isDisabled: boolean;
  $isBlockAddingSlide: boolean;
  $isShowError: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
  height: 41px;
  color: ${({ theme }) => theme.colors.text.main};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px 0px;
  background-color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.background.module.disabled : theme.colors.background.module.main};
  font-weight: 600;
  font-size: 14px;
  min-width: 217px;
  margin-bottom: 10px;
  user-select: none;
  pointer-events: ${({ $isDisabled, $isBlockAddingSlide }) =>
    $isDisabled || $isBlockAddingSlide ? 'none' : ''};

  ${({ $isShowError }) =>
    $isShowError &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.authLayoutError};
    `};
`;
