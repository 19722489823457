import { createSlice } from '@reduxjs/toolkit';

import {
  ESlalepointInfoListTableColumnArrow,
  IFetchSalepointInfoListData,
  ISalepointCollectorListItem,
  ISalepointInfoFilterItem,
  ISalepointInfoListItem,
  ISalepointInfoShopGroupListItem,
  ISalepointInfoShopListItem,
  TSalepointInfoListSorting,
} from '../types';
import {
  fetchSalepointCollectorList,
  fetchSalepointInfoFilterList,
  fetchSalepointInfoListData,
  fetchSalepointInfoShopList,
  refetchSalepointInfoListDataWithCursor,
} from './salepointInfoListDashboard.actions';
import { ERequestStatus } from '@/shared/lib/types';
import {
  salepointInfoListDataLong,
  salepointInfoListDataShort,
} from '../stub/salepointInfoListData';
import { salepointInfoFilterListData } from '../stub/salepointInfoFilterListData';
import { salepointInfoShopListData } from '../stub/salepointInfoShopListData';
import { collectorListData } from '../stub/collectorListData';

interface ISalepointInfoListDashboardState {
  salepointInfoListData: IFetchSalepointInfoListData;
  lastSalepointInfoListDataWithCursorRefetched: IFetchSalepointInfoListData;
  salepointInfoList: ISalepointInfoListItem[];
  fetchSalepointInfoListDataStatus: ERequestStatus;
  salepointInfoFilterList: ISalepointInfoFilterItem[];
  fetchSalepointInfoFilterListStatus: ERequestStatus;
  shopGroupList: ISalepointInfoShopGroupListItem[];
  fetchSalepointInfoShopListStatus: ERequestStatus;
  selectedFilter: ISalepointInfoFilterItem | null;
  shopIdFilter: string;
  searchByShopAddress: string;
  selectedSorting: TSalepointInfoListSorting | null;
  tableColumnArrow: ESlalepointInfoListTableColumnArrow;
  salepointCollectorList: ISalepointCollectorListItem[];
  fetchSalepointCollectorListStatus: ERequestStatus;
  salepointInfoListCursors: number[];
}

const initialState: ISalepointInfoListDashboardState = {
  salepointInfoListData: { salepoint_info_list: [] }, // salepointInfoListDataLong,
  lastSalepointInfoListDataWithCursorRefetched: null,
  salepointInfoList: [], // salepointInfoListDataLong.salepoint_info_list,
  fetchSalepointInfoListDataStatus: ERequestStatus.idle,
  salepointInfoFilterList: [], // salepointInfoFilterListData,
  fetchSalepointInfoFilterListStatus: ERequestStatus.idle,
  shopGroupList: [], // salepointInfoShopListData,
  fetchSalepointInfoShopListStatus: ERequestStatus.idle,
  selectedFilter: null,
  shopIdFilter: '',
  searchByShopAddress: '',
  selectedSorting: null,
  tableColumnArrow: ESlalepointInfoListTableColumnArrow.none,
  salepointCollectorList: [], // collectorListData,
  fetchSalepointCollectorListStatus: ERequestStatus.idle,
  salepointInfoListCursors: [],
};

const salepointInfoListDashboard = createSlice({
  name: 'salepointInfoListDashboard',
  initialState,
  reducers: {
    setSelectedFilter: (state, action) => {
      if (action.payload !== null) {
        const filterId = Number(action.payload);
        const selectedFilter = state.salepointInfoFilterList.find((filterItem) => {
          return filterItem.id === filterId;
        });
        if (selectedFilter) {
          state.selectedFilter = selectedFilter;
        }
      } else {
        state.selectedFilter = action.payload;
      }
    },
    setShopIdFilter: (state, action) => {
      if (action.payload === '') {
        state.shopIdFilter = action.payload;
      } else {
        const shopId = parseInt(action.payload);
        if (!isNaN(shopId)) {
          state.shopIdFilter = shopId.toString();
        }
      }
    },
    setSearchByShopAddress: (state, action) => {
      state.searchByShopAddress = action.payload;
    },
    setSearchByShopId: (state, action) => {
      const shopId = action.payload;
      let foundShop: ISalepointInfoShopListItem;
      state.shopGroupList.find((shopGroup) => {
        return shopGroup?.shops.find((shop) => {
          if (shopId === shop.id) {
            foundShop = shop;
          }
          return shopId === shop.id;
        });
      });

      if (foundShop) {
        state.searchByShopAddress = foundShop.address;
      }
    },
    setSelectedSorting: (state, action) => {
      state.selectedSorting = action.payload;
    },
    setTableColumnArrow: (state, action) => {
      state.tableColumnArrow = action.payload;
    },
    clearSalepointInfoListCursors: (state) => {
      state.salepointInfoListCursors = [];
    },
    setSalepointInfoList: (state, action) => {
      state.salepointInfoList = action.payload;
    },
    addSalepointInfoListNextCursor: (state, action) => {
      const foundCursor = state.salepointInfoListCursors.find((currentCursor) => {
        return currentCursor === action.payload;
      });

      if (foundCursor) {
        state.salepointInfoListCursors.push(action.payload);
      }
    },
    setLastSalepointInfoListDataWithCursorRefetched: (state, action) => {
      state.lastSalepointInfoListDataWithCursorRefetched = action.payload;
    },
    clearLastSalepointInfoListDataWithCursorRefetched: (state) => {
      state.lastSalepointInfoListDataWithCursorRefetched = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSalepointInfoListData.pending, (state) => {
        state.fetchSalepointInfoListDataStatus = ERequestStatus.pending;
      })
      .addCase(fetchSalepointInfoListData.fulfilled, (state, action) => {
        state.fetchSalepointInfoListDataStatus = ERequestStatus.success;
        const { data: salepointInfoListDataResponse, isLazyLoading } = action.payload;
        const salepointInfoListResponse = salepointInfoListDataResponse?.salepoint_info_list;

        const lastRefetchedNextCursorId =
          state.lastSalepointInfoListDataWithCursorRefetched?.next_cursor;

        if (isLazyLoading) {
          if (
            (lastRefetchedNextCursorId !== undefined &&
              lastRefetchedNextCursorId !== state.salepointInfoListData.next_cursor) ||
            state.lastSalepointInfoListDataWithCursorRefetched === null
          ) {
            state.salepointInfoList = [...state.salepointInfoList, ...salepointInfoListResponse];
          }
        } else {
          state.salepointInfoList = salepointInfoListResponse;
        }
        state.salepointInfoListData = salepointInfoListDataResponse;
      })
      .addCase(fetchSalepointInfoListData.rejected, (state) => {
        state.fetchSalepointInfoListDataStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchSalepointInfoFilterList.pending, (state) => {
        state.fetchSalepointInfoFilterListStatus = ERequestStatus.pending;
      })
      .addCase(fetchSalepointInfoFilterList.fulfilled, (state, action) => {
        state.fetchSalepointInfoFilterListStatus = ERequestStatus.success;
        state.salepointInfoFilterList = action.payload;
      })
      .addCase(fetchSalepointInfoFilterList.rejected, (state) => {
        state.fetchSalepointInfoFilterListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchSalepointInfoShopList.pending, (state) => {
        state.fetchSalepointInfoShopListStatus = ERequestStatus.pending;
      })
      .addCase(fetchSalepointInfoShopList.fulfilled, (state, action) => {
        state.fetchSalepointInfoShopListStatus = ERequestStatus.success;
        state.shopGroupList = action.payload;
      })
      .addCase(fetchSalepointInfoShopList.rejected, (state) => {
        state.fetchSalepointInfoShopListStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchSalepointCollectorList.pending, (state) => {
        state.fetchSalepointCollectorListStatus = ERequestStatus.pending;
      })
      .addCase(fetchSalepointCollectorList.fulfilled, (state, action) => {
        state.fetchSalepointCollectorListStatus = ERequestStatus.success;
        state.salepointCollectorList = action.payload;
      })
      .addCase(fetchSalepointCollectorList.rejected, (state) => {
        state.fetchSalepointCollectorListStatus = ERequestStatus.error;
      });
  },
});

export const {
  setSelectedFilter,
  setShopIdFilter,
  setSearchByShopAddress,
  setSearchByShopId,
  setSelectedSorting,
  setTableColumnArrow,
  clearSalepointInfoListCursors,
  setSalepointInfoList,
  addSalepointInfoListNextCursor,
  setLastSalepointInfoListDataWithCursorRefetched,
  clearLastSalepointInfoListDataWithCursorRefetched,
} = salepointInfoListDashboard.actions;

export default salepointInfoListDashboard.reducer;
