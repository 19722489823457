import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ISlide } from '@/features/stories/types';
import { ActionList, DeleteSlideButton, SlideContainer, SlideWrapper, Symbol } from './elements';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@/shared/lib/const';
import { TRootState } from '@/store';
import { isValidSlide } from '@/features/stories/utils';
import { IRemoveSlideModalHandleOpenData } from '@/features/stories/story';

export interface ISlideOwnProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isShowFormErrors: boolean;
  handleClassName?: string;
  handleRemoveSlideModalOpen?: (data: IRemoveSlideModalHandleOpenData) => void;
}

export type TSlideProps = ISlideOwnProps & ISlide;

export const Slide: FC<TSlideProps> = ({
  external_slide_id: externalSlideId,
  name,
  isNewSlide,
  onClick,
  isShowFormErrors,
  handleClassName,
  handleRemoveSlideModalOpen,
}) => {
  const navigate = useNavigate();
  const { scenario, story: storyId } = useParams();
  const story = useSelector((state: TRootState) => state.stories.story);

  const isValidSlideConst = useMemo(() => {
    const slide = story.slides.find((currSlide) => {
      return currSlide.external_slide_id === externalSlideId;
    });

    if (slide) {
      return isValidSlide(slide, story.isNewStory);
    }
    return true;
  }, [story]);

  const handleSlideContainerClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick(event);
    navigate(
      ROUTES.slide
        .replace(':scenario', scenario)
        .replace(':story', storyId)
        .replace(':slide', isNewSlide ? 'new_slide' : externalSlideId),
      {
        state: { isShowStoryFormErrors: isShowFormErrors, from: location.pathname },
      }
    );
  };

  const handleRemoveSlide = () => {
    handleRemoveSlideModalOpen({
      scenario,
      externalStoryId: storyId,
      externalSlideId,
      isNewSlide,
    });
  };

  return (
    <SlideContainer>
      <SlideWrapper
        onClick={handleSlideContainerClick}
        $isShowError={!isValidSlideConst && isShowFormErrors}
        className={handleClassName}
      >
        {isNewSlide ? 'Новый слайд' : name}
      </SlideWrapper>
      <ActionList>
        <DeleteSlideButton
          // @ts-expect-error: Unreachable code error
          color="lightGrey"
          variant="outlined"
          component="label"
          onClick={handleRemoveSlide}
        >
          <Symbol>delete</Symbol>
        </DeleteSlideButton>
      </ActionList>
    </SlideContainer>
  );
};
