import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Collectors = styled(Box)`
  font-weight: 400;
  font-size: 12px;
  color: #909090;
  margin-bottom: 10px;

  @media (max-width: 1700px) {
    font-size: 10px;
  }
`;
