import Box from '@mui/material/Box';
import styled from 'styled-components';

export const CloseIconWrapper = styled(Box)<{ $clearTextButtonShown: boolean }>`
  position: absolute;
  display: inline-flex;
  visibility: ${({ $clearTextButtonShown }) => ($clearTextButtonShown ? 'visible' : 'hidden')};
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  right: 64px;
  padding: 5px;
  cursor: pointer;
`;
