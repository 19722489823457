import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: block;
  text-decoration: none;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;
