import { Button } from '@mui/material';
import styled from 'styled-components';

export const ConfirmBtn = styled(Button)`
  &.remove-slide-modal-btn {
    width: 100%;
    color: #2b2a33;
    background-color: #ffffff;
    margin-bottom: 10px;

    &:hover {
      background-color: #dee3ed;
    }
  }
`;
