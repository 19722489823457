import styled from 'styled-components';

export const SalepointInfoListDashboardControlsWrapper = styled.div`
  position: absolute;
  top: 76px;
  left: 32px;
  width: 462px;
  z-index: 1;

  @media (max-width: 1700px) {
    left: 24px;
    width: 380px;
  }
`;
