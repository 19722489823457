import { ChangeEvent, KeyboardEvent, useEffect, FC, FocusEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';

import { StoryBasicInfoContainer, StoryNameAndIdTextField } from './elements';
import { TextFieldGroup } from '@/shared/components/TextFieldGroup';
import { TRootState, useAppDispatch } from '@/store';
import { setNewStoryExternalId, setActiveStoryName } from '@/features/stories/redux/stories.slice';
import { useParams } from 'react-router-dom';
import { IStoryFormikValues } from '../..';
import { CustomTextField } from '@/shared/components/CustomTextField';

interface IStoryBasicInfo {
  formik: FormikProps<IStoryFormikValues>;
}

export const StoryBasicInfo: FC<IStoryBasicInfo> = ({ formik }) => {
  const { scenario, story: externalStoryId } = useParams();
  const dispatch = useAppDispatch();
  const story = useSelector((state: TRootState) => state.stories.story);

  useEffect(() => {
    formik.setFieldValue('storyExternalId', story.external_story_id);
    formik.setFieldValue('storyName', story.name);
  }, [story]);

  const handleStoryExternalIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('storyExternalId', event.target.value);
    dispatch(setNewStoryExternalId({ value: event.target.value, scenario, externalStoryId }));
  };
  const handleStoryExternalIdInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (story.isNewStory && !story.isSavedStory) {
      dispatch(
        setNewStoryExternalId({ value: formik.values.storyExternalId, scenario, externalStoryId })
      );
    }
  };
  const handleStoryExternalIdInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (story.isNewStory && !story.isSavedStory) {
        dispatch(
          setNewStoryExternalId({ value: formik.values.storyExternalId, scenario, externalStoryId })
        );
      }
    }
  };

  const handleStoryNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('storyName', event.target.value);
    dispatch(setActiveStoryName({ value: event.target.value, scenario, externalStoryId }));
  };
  const handleStoryNameInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    dispatch(setActiveStoryName({ value: formik.values.storyName, scenario, externalStoryId }));
  };
  const handleStoryNameInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(setActiveStoryName({ value: formik.values.storyName, scenario, externalStoryId }));
    }
  };

  return (
    <StoryBasicInfoContainer>
      <TextFieldGroup marginRight="10px" width="217px" title="Стори">
        <CustomTextField
          label="ID"
          size="small"
          sx={{ mb: '10px', flexGrow: null, flexBasis: null }}
          value={story?.id}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          displayCopyBtn
        />
        <StoryNameAndIdTextField
          label="Внешний идентификатор"
          type="text"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: !story.isNewStory || (story.isNewStory && story.isSavedStory),
          }}
          value={formik.values.storyExternalId}
          name="storyExternalId"
          onChange={handleStoryExternalIdInputChange}
          onBlur={handleStoryExternalIdInputBlur}
          onKeyUp={handleStoryExternalIdInputKeyUp}
          error={!!formik.touched.storyExternalId && !!formik.errors.storyExternalId}
          helperText={!!formik.touched.storyExternalId && formik.errors.storyExternalId}
        />
        <StoryNameAndIdTextField
          label="Имя"
          type="text"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={formik.values.storyName}
          name="storyName"
          onChange={handleStoryNameInputChange}
          onBlur={handleStoryNameInputBlur}
          onKeyUp={handleStoryNameInputKeyUp}
          error={!!formik.touched.storyName && !!formik.errors.storyName}
          helperText={!!formik.touched.storyName && formik.errors.storyName}
        />
      </TextFieldGroup>
    </StoryBasicInfoContainer>
  );
};
