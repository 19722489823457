import { FC } from 'react';
import { Box } from '@mui/material';

import {
  EPromotionCollectionTabPages,
  IPromotionCollectionItemStatus,
} from '@/features/promotions/types';
import {
  PromotionCollectionItemDataWrapper,
  PromotionCollectionItemImg,
  PromotionCollectionItemName,
  PromotionCollectionItemStatus,
  PromotionCollectionItemWrapper,
  Symbol,
} from './elements';
import { useAppDispatch } from '@/store';
import {
  setPromotionCollectionActivePage,
  setActivePromotionCollectionId,
} from '@/features/promotions/redux/promotions.slice';

interface IPromotionCollectionListItemComponentProps {
  id: number;
  name: string;
  status: IPromotionCollectionItemStatus;
  image_url: string;
  handleClassName?: string;
}

export const PromotionCollectionListItemComponent: FC<
  IPromotionCollectionListItemComponentProps
> = ({ id, name, status, image_url, handleClassName }) => {
  const dispatch = useAppDispatch();

  const handlePromotionCollectionItemClick = () => {
    dispatch(setActivePromotionCollectionId(id));
    dispatch(
      setPromotionCollectionActivePage(EPromotionCollectionTabPages.existingPromotionCollection)
    );
  };

  return (
    <PromotionCollectionItemWrapper
      onClick={handlePromotionCollectionItemClick}
      className={handleClassName}
    >
      <PromotionCollectionItemImg $img={image_url}>
        {!image_url && <Symbol>image</Symbol>}
      </PromotionCollectionItemImg>
      <PromotionCollectionItemDataWrapper>
        <PromotionCollectionItemName title={name}>{name}</PromotionCollectionItemName>
        <PromotionCollectionItemStatus>{status?.name}</PromotionCollectionItemStatus>
      </PromotionCollectionItemDataWrapper>
    </PromotionCollectionItemWrapper>
  );
};
