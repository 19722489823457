import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div<{ $withGradient?: boolean }>`
  height: 100vh;
  width: 335px;
  background-color: ${({ theme }) => theme.colors.dispatchersWorkplace.sidebarMenu.background};

  @media (max-width: 1700px) {
    width: 255px;
  }
`;
