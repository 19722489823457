import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import {
  newPromotion,
  newPromotionCollection,
  promotionCollectionStatusNames,
  promotionVisualizationDataEmpty,
} from '../const';
import {
  EPromotionTabPages,
  IPromotionCategory,
  IPromotionItem,
  IPromotionType,
  IPromotionFilters,
  IPromotionCollectionListItem,
  EPromotionCollectionTabPages,
  IPromotionCollectionItem,
  IPromotionCollectionItemPromotion,
  IPromotionCatalog,
  IPromotionVisualizationData,
} from '../types';
import {
  fetchPromotionCategories,
  fetchPromotionCollectionItem,
  fetchPromotionCollectionListItems,
  fetchPromotionItem,
  fetchPromotionItems,
  fetchPromotionTypes,
  fetchPromotionCatalogUrl,
  savePromotionCollectionItem,
  fetchPromotionVisualizationData,
} from './promotions.actions';
import { ERequestStatus } from '@/shared/lib/types';
import { promotionCollections as promotionCollectionsStubData } from '../PromotionCollectionsTab/stubData/promotionCollections';
import { EPromotionCollectionTabs } from '../PromotionCollectionsTab/PromotionCollectionList';
import { promotionCollection } from '../PromotionCollectionsTab/stubData/promotionCollection';
import { promotionVisualizationData } from '../stub/promotionVisualizationData';
import { trimPromotionColorFields } from '../utils';

interface IPromotionsState {
  promotionsTab: {
    activePage: EPromotionTabPages;
  };
  promotionTypes: IPromotionType[];
  fetchPromotionTypesStatus: ERequestStatus;
  promotionCategories: IPromotionCategory[];
  fetchPromotionCategoriesStatus: ERequestStatus;
  promotionItems: IPromotionItem[];
  promotionItemsFiletred: IPromotionItem[];
  fetchPromotionItemsStatus: ERequestStatus;
  promotionInitial: IPromotionItem;
  promotion: IPromotionItem;
  promotionFilters: IPromotionFilters;
  fetchPromotionItemStatus: ERequestStatus;
  promotionVisualizationData: IPromotionVisualizationData;
  fetchPromotionVisualizationDataStatus: ERequestStatus;
  selectedPromotionIds: number[];
  promotionCollections: IPromotionCollectionListItem[];
  promotionCollectionSearch: string;
  promotionCollectionsTabValue: number;
  promotionCollectionActivePage: EPromotionCollectionTabPages;
  fetchPromotionCollectionItemStatus: ERequestStatus;
  promotionCollectionInitial: IPromotionCollectionItem;
  promotionCollection: IPromotionCollectionItem;
  activePromotionCollectionId: number;
  promotionCatalog: IPromotionCatalog;
  fetchPromotionCatalogUrlStatus: ERequestStatus;
}

const initialState: IPromotionsState = {
  promotionsTab: {
    activePage: EPromotionTabPages.promotionList,
  },
  promotionTypes: [],
  fetchPromotionTypesStatus: ERequestStatus.idle,
  promotionCategories: [],
  fetchPromotionCategoriesStatus: ERequestStatus.idle,
  promotionItems: [],
  promotionItemsFiletred: [],
  fetchPromotionItemsStatus: ERequestStatus.idle,
  promotionInitial: cloneDeep(newPromotion),
  promotion: cloneDeep(newPromotion),
  promotionVisualizationData: promotionVisualizationDataEmpty,
  fetchPromotionVisualizationDataStatus: ERequestStatus.idle,
  promotionFilters: {
    name: '',
    startDate: null,
    finishDate: null,
    statusKey: null,
    typeId: null,
    categoryId: null,
  },
  fetchPromotionItemStatus: ERequestStatus.idle,
  selectedPromotionIds: [],
  promotionCollections: [],
  promotionCollectionSearch: '',
  promotionCollectionsTabValue: EPromotionCollectionTabs.drafts,
  promotionCollectionActivePage: EPromotionCollectionTabPages.promotionCollectionList,
  fetchPromotionCollectionItemStatus: ERequestStatus.idle,
  promotionCollectionInitial: cloneDeep(newPromotionCollection),
  promotionCollection: cloneDeep(newPromotionCollection),
  activePromotionCollectionId: null,
  promotionCatalog: {
    file_url: '',
  },
  fetchPromotionCatalogUrlStatus: ERequestStatus.idle,
};

const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    setPromotionAtivePage: (state, action) => {
      state.promotionsTab.activePage = action.payload;
    },
    resetNewPromotion: (state) => {
      state.promotionInitial = cloneDeep(newPromotion);
      state.promotion = cloneDeep(newPromotion);
    },
    setPromotionName: (state, action) => {
      state.promotion.name = action.payload;
    },
    setPromotionType: (state, action) => {
      state.promotion.type = action.payload;
    },
    setPromotionCategories: (state, action) => {
      state.promotion.categories = action.payload;
    },
    setPromotionStatus: (state, action) => {
      state.promotion.status = action.payload;
    },
    setPromotionListImage: (state, action) => {
      state.promotion.list_image = action.payload;
    },
    setPromotionFullImage: (state, action) => {
      state.promotion.full_image = action.payload;
    },
    setPromotionDescription: (state, action) => {
      state.promotion.description = action.payload;
    },
    setPromotionCondition: (state, action) => {
      state.promotion.condition = action.payload;
    },
    setPromotionRequirements: (state, action) => {
      state.promotion.requirements = action.payload;
    },
    setPromotionButtonValue: (state, action) => {
      if (!state.promotion.button) {
        state.promotion.button = {
          params: {},
        };
      }
      if (!state.promotion.button.params.name) {
        state.promotion.button.params.name = newPromotion.button.params.name;
      }
      if (!state.promotion.button.params.type) {
        state.promotion.button.params.type = newPromotion.button.params.type;
      }
      state.promotion.button.params.value = action.payload;
    },
    setPromotionButtonBackgroundColor: (state, action) => {
      if (!state.promotion.button) {
        state.promotion.button = {
          params: {},
        };
      }
      if (!state.promotion.button.params.name) {
        state.promotion.button.params.name = newPromotion.button.params.name;
      }
      if (!state.promotion.button.params.type) {
        state.promotion.button.params.type = newPromotion.button.params.type;
      }
      state.promotion.button.params.background_color = action.payload;
    },
    setPromotionButtonTextColor: (state, action) => {
      if (!state.promotion.button) {
        state.promotion.button = {
          params: {},
        };
      }
      if (!state.promotion.button.params.name) {
        state.promotion.button.params.name = newPromotion.button.params.name;
      }
      if (!state.promotion.button.params.type) {
        state.promotion.button.params.type = newPromotion.button.params.type;
      }
      state.promotion.button.params.text_color = action.payload;
    },
    setPromotionButtonActionType: (state, action) => {
      if (!state.promotion.button) {
        state.promotion.button = {
          params: {},
        };
      }
      if (!state.promotion.button.params.name) {
        state.promotion.button.params.name = newPromotion.button.params.name;
      }
      if (!state.promotion.button.params.type) {
        state.promotion.button.params.type = newPromotion.button.params.type;
      }

      if (!state.promotion.button.params.on_click) {
        state.promotion.button.params.on_click = {};
      }
      state.promotion.button.params.on_click.action_type = action.payload;
    },
    setPromotionButtonActionValue: (state, action) => {
      if (!state.promotion.button) {
        state.promotion.button = {
          params: {},
        };
      }
      if (!state.promotion.button.params.name) {
        state.promotion.button.params.name = newPromotion.button.params.name;
      }
      if (!state.promotion.button.params.type) {
        state.promotion.button.params.type = newPromotion.button.params.type;
      }

      if (!state.promotion.button.params.on_click) {
        state.promotion.button.params.on_click = {};
      }
      state.promotion.button.params.on_click.action_value = action.payload;
    },
    setPromotionPublicDatetime: (state, action) => {
      state.promotion.public_datetime = action.payload;
    },
    setPromotionStartDatetime: (state, action) => {
      state.promotion.start_datetime = action.payload;
    },
    setPromotionFinishDatetime: (state, action) => {
      state.promotion.finish_datetime = action.payload;
    },
    setFilterPromotionsByName: (state, action) => {
      state.promotionFilters.name = action.payload;
    },
    setFilterPromotionsByStartDate: (state, action) => {
      state.promotionFilters.startDate = action.payload;
    },
    setFilterPromotionsByFinishDate: (state, action) => {
      state.promotionFilters.finishDate = action.payload;
    },
    setFilterPromotionsByStatus: (state, action) => {
      state.promotionFilters.statusKey = action.payload;
    },
    setFilterPromotionsByType: (state, action) => {
      state.promotionFilters.typeId = action.payload;
    },
    setFilterPromotionsByCategory: (state, action) => {
      state.promotionFilters.categoryId = action.payload;
    },
    setSelectedPromotionIds: (state, action) => {
      state.selectedPromotionIds = action.payload;
    },
    updatePromotionCollectionSearch: (state, action) => {
      state.promotionCollectionSearch = action.payload;
    },
    setPromotionCollectionListItems: (state, action) => {
      state.promotionCollections = action.payload;
    },
    setPromotionCollectionsTabValue: (state, action) => {
      state.promotionCollectionsTabValue = action.payload;
    },
    setPromotionCollectionActivePage: (state, action) => {
      state.promotionCollectionActivePage = action.payload;
    },
    resetPromotionCollection: (state) => {
      state.promotionCollection = cloneDeep(newPromotionCollection);
      state.promotionCollectionInitial = cloneDeep(newPromotionCollection);
    },
    setActivePromotionCollectionId: (state, action) => {
      state.activePromotionCollectionId = action.payload;
    },
    resetActivePromotionCollectionId: (state) => {
      state.activePromotionCollectionId = null;
    },
    setPromotionCollectionName: (state, action) => {
      state.promotionCollection.name = action.payload;
    },
    setPromotionCollectionListImage: (state, action) => {
      state.promotionCollection.title_url = action.payload;
    },
    setPromotionCollectionMainImage: (state, action) => {
      state.promotionCollection.image_url = action.payload;
    },
    mergePromotionCollectionPromotions: (state, action) => {
      const promotionsToAdd: IPromotionItem[] = action.payload;
      const uniquePromotions = promotionsToAdd?.filter((currPromotionToAdd) => {
        const existingPromotion = state.promotionCollection.promotions?.find(
          (existingPromotion) => {
            return existingPromotion.id === currPromotionToAdd.id;
          }
        );
        return !existingPromotion;
      });
      state.promotionCollection.promotions = [
        ...(state.promotionCollection.promotions || []),
        ...uniquePromotions,
      ];
    },
    removePromotionCollectionPromotionById: (state, action) => {
      const promotionIdToDelete = action.payload;
      state.promotionCollection.promotions = state.promotionCollection.promotions?.filter(
        (promotion) => {
          return promotionIdToDelete !== promotion.id;
        }
      );
    },
    setPromotionCollectionIsPublishedTruthy: (state) => {
      state.promotionCollection.is_published = true;
    },
    setPromotionCatalogImage: (state, action) => {
      state.promotionCatalog.file_url = action.payload;
    },
    setPromotionVisualizationDataEmpty: (state) => {
      state.promotionVisualizationData = promotionVisualizationDataEmpty;
    },
    clonePromotion: (state) => {
      state.promotionInitial = cloneDeep(newPromotion);
      const clonedPromotion = cloneDeep(state.promotion);
      delete clonedPromotion.id;
      delete clonedPromotion.version;
      clonedPromotion.name = '';
      clonedPromotion.status = null;

      state.promotion = clonedPromotion;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPromotionTypes.pending, (state, action) => {
        state.fetchPromotionTypesStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionTypes.fulfilled, (state, action) => {
        state.fetchPromotionTypesStatus = ERequestStatus.success;
        state.promotionTypes = action.payload;
      })
      .addCase(fetchPromotionTypes.rejected, (state, action) => {
        state.fetchPromotionTypesStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionCategories.pending, (state, action) => {
        state.fetchPromotionCategoriesStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionCategories.fulfilled, (state, action) => {
        state.fetchPromotionCategoriesStatus = ERequestStatus.success;
        state.promotionCategories = action.payload;
      })
      .addCase(fetchPromotionCategories.rejected, (state, action) => {
        state.fetchPromotionCategoriesStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionItems.pending, (state, action) => {
        state.fetchPromotionItemsStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionItems.fulfilled, (state, action) => {
        state.fetchPromotionItemsStatus = ERequestStatus.success;
        state.promotionItems = action.payload;
        state.promotionItemsFiletred = action.payload;
      })
      .addCase(fetchPromotionItems.rejected, (state, action) => {
        state.fetchPromotionItemsStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionItem.pending, (state, action) => {
        state.fetchPromotionItemStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionItem.fulfilled, (state, action) => {
        state.fetchPromotionItemStatus = ERequestStatus.success;
        state.promotionInitial = cloneDeep(action.payload);
        state.promotion = trimPromotionColorFields(action.payload);
      })
      .addCase(fetchPromotionItem.rejected, (state, action) => {
        state.fetchPromotionItemStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionVisualizationData.pending, (state, action) => {
        state.fetchPromotionVisualizationDataStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionVisualizationData.fulfilled, (state, action) => {
        state.fetchPromotionVisualizationDataStatus = ERequestStatus.success;
        const data = action.payload;
        state.promotionVisualizationData = {
          short_promotion: {
            card: data.short_promotion.card,
            templates: data.short_promotion.template,
          },
          full_promotion: {
            card: data.full_promotion.card,
            templates: data.full_promotion.template,
          },
        };
      })
      .addCase(fetchPromotionVisualizationData.rejected, (state, action) => {
        state.fetchPromotionVisualizationDataStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionCollectionListItems.pending, (state, action) => {
        state.fetchPromotionItemStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionCollectionListItems.fulfilled, (state, action) => {
        state.fetchPromotionItemStatus = ERequestStatus.success;
        state.promotionCollections = action.payload;
      })
      .addCase(fetchPromotionCollectionListItems.rejected, (state, action) => {
        state.fetchPromotionItemStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionCollectionItem.pending, (state, action) => {
        state.fetchPromotionCollectionItemStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionCollectionItem.fulfilled, (state, action) => {
        state.fetchPromotionCollectionItemStatus = ERequestStatus.success;
        state.promotionCollection = action.payload;
        state.promotionCollectionInitial = action.payload;
      })
      .addCase(fetchPromotionCollectionItem.rejected, (state, action) => {
        state.fetchPromotionCollectionItemStatus = ERequestStatus.error;
      });

    builder
      .addCase(fetchPromotionCatalogUrl.pending, (state) => {
        state.fetchPromotionCatalogUrlStatus = ERequestStatus.pending;
      })
      .addCase(fetchPromotionCatalogUrl.fulfilled, (state, action) => {
        state.fetchPromotionCatalogUrlStatus = ERequestStatus.success;
        state.promotionCatalog = action.payload;
      })
      .addCase(fetchPromotionCatalogUrl.rejected, (state) => {
        state.fetchPromotionCatalogUrlStatus = ERequestStatus.error;
      });

    builder.addCase(savePromotionCollectionItem.fulfilled, (state, action) => {
      state.promotionCollection = cloneDeep(newPromotionCollection);
      state.promotionCollectionInitial = cloneDeep(newPromotionCollection);
    });
  },
});

export const {
  setPromotionAtivePage,
  resetNewPromotion,
  setPromotionName,
  setPromotionType,
  setPromotionCategories,
  setPromotionStatus,
  setPromotionListImage,
  setPromotionFullImage,
  setPromotionDescription,
  setPromotionCondition,
  setPromotionRequirements,
  setPromotionButtonValue,
  setPromotionButtonBackgroundColor,
  setPromotionButtonTextColor,
  setPromotionButtonActionType,
  setPromotionButtonActionValue,
  setPromotionPublicDatetime,
  setPromotionStartDatetime,
  setPromotionFinishDatetime,
  setFilterPromotionsByName,
  setFilterPromotionsByStartDate,
  setFilterPromotionsByFinishDate,
  setFilterPromotionsByStatus,
  setFilterPromotionsByType,
  setFilterPromotionsByCategory,
  setSelectedPromotionIds,
  updatePromotionCollectionSearch,
  setPromotionCollectionListItems,
  setPromotionCollectionsTabValue,
  setPromotionCollectionActivePage,
  resetPromotionCollection,
  setActivePromotionCollectionId,
  resetActivePromotionCollectionId,
  setPromotionCollectionName,
  setPromotionCollectionListImage,
  setPromotionCollectionMainImage,
  mergePromotionCollectionPromotions,
  removePromotionCollectionPromotionById,
  setPromotionCollectionIsPublishedTruthy,
  setPromotionCatalogImage,
  setPromotionVisualizationDataEmpty,
  clonePromotion,
} = promotionsSlice.actions;

export default promotionsSlice.reducer;
