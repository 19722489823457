import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { TRootState, useAppDispatch } from '@/store';
import { Slide as SlideContainer } from './elements';
import { PlusIcon } from './elements';
import { addSlideToStory } from '@/features/stories/redux/stories.slice';
import { ERequestStatus } from '@/shared/lib/types';

export interface IAddSlide {
  isShowFormErrors: boolean;
}

export const AddSlide: FC<IAddSlide> = ({ isShowFormErrors }) => {
  const { scenario, story: externalStoryId } = useParams();
  const dispatch = useAppDispatch();
  const saveStoryStatus = useSelector((state: TRootState) => state.stories.saveStoryStatus);
  const story = useSelector((state: TRootState) => state.stories.story);

  const isNoSlides = useMemo(() => {
    return story?.slides?.length === 0;
  }, [story]);

  const isBlockAddingSlide = useMemo(() => {
    return saveStoryStatus === ERequestStatus.pending;
  }, [saveStoryStatus]);

  const isAddingSlideDisabled = useMemo(() => {
    if (!!story && !!story.slides) {
      const newSlideIndex = story.slides.findIndex((slide) => {
        return slide.isNewSlide;
      });
      if (newSlideIndex === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [story]);

  const handleAddSlideClick = () => {
    if (!isAddingSlideDisabled) {
      dispatch(addSlideToStory({ scenario, externalStoryId }));
    }
  };

  return (
    <SlideContainer
      $isDisabled={isAddingSlideDisabled}
      $isBlockAddingSlide={isBlockAddingSlide}
      onClick={handleAddSlideClick}
      $isShowError={isNoSlides && isShowFormErrors}
    >
      <PlusIcon>add</PlusIcon>
    </SlideContainer>
  );
};
