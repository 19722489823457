import { Modal } from '@mui/material';
import styled from 'styled-components';

export const RemoveSlideModalMUI = styled(Modal)`
  background-color: rgb(255, 255, 255, 0.65);

  & .MuiBackdrop-root.MuiModal-backdrop {
    background-color: transparent !important;
  }
`;
