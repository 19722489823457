import { ChangeEvent, KeyboardEvent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  ControlsWrapperBottom,
  SalepointInfoListDashboardContainer,
  SalepointInfoListDashboardControlsWrapper,
  SalepointInfoListTableWrapper,
} from './elements';
import { TRootState, useAppDispatch } from '@/store';
import {
  fetchSalepointInfoFilterList,
  fetchSalepointInfoListData,
  fetchSalepointInfoShopList,
  refetchSalepointInfoListDataWithCursor,
} from '../redux/salepointInfoListDashboard.actions';
import SalepointInfoListTable from './components/SalepointInfoListTable';
import { CustomSelectDwp } from '../../shared/components/CustomSelectDwp';
import { SearchInputDwp } from '../../shared/components/SearchInputDwp ';
import {
  clearSalepointInfoListCursors,
  setSalepointInfoList,
  setLastSalepointInfoListDataWithCursorRefetched,
  setSearchByShopAddress,
  setSearchByShopId,
  setSelectedFilter,
  setShopIdFilter,
  clearLastSalepointInfoListDataWithCursorRefetched,
} from '../redux/salepointInfoListDashboard.slice';
import { ShopAddressSelect } from '../../shared/components/ShopAddressSelect';
import {
  EFetchSalepointInfoCursor,
  ESlalepointInfoListTableColumnArrow,
  ISalepointInfoListItem,
} from '../types';

const DEFAULT_UPDATE_SALEPOINT_INFO_LIST_DATA_INTERVAL_MS = 3000;

const SalepointInfoListDashboard = () => {
  const dispatch = useAppDispatch();
  const salepointInfoFilterList = useSelector(
    (state: TRootState) =>
      state.dispatchersWorkplace.salepointInfoListDashboard.salepointInfoFilterList
  );
  const selectedSorting = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.selectedSorting
  );
  const tableColumnArrow = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.tableColumnArrow
  );
  const selectedFilter = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.selectedFilter
  );
  const shopIdFilter = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.shopIdFilter
  );
  const shopGroupList = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.shopGroupList
  );
  const searchByShopAddress = useSelector(
    (state: TRootState) => state.dispatchersWorkplace.salepointInfoListDashboard.searchByShopAddress
  );
  const salepointInfoListCursors = useSelector(
    (state: TRootState) =>
      state.dispatchersWorkplace.salepointInfoListDashboard.salepointInfoListCursors
  );
  const isLaptopSmallerScreenWidth = useMediaQuery('(max-width:1700px)');

  const filterListOptionValues = useMemo(() => {
    if (salepointInfoFilterList && salepointInfoFilterList.length > 0) {
      return salepointInfoFilterList.map((filterItem) => {
        return filterItem.id;
      });
    }
    return [];
  }, [salepointInfoFilterList]);

  const filterListOptionTexts = useMemo(() => {
    if (salepointInfoFilterList && salepointInfoFilterList.length > 0) {
      return salepointInfoFilterList.map((filterItem) => {
        return filterItem.title;
      });
    }
    return [];
  }, [salepointInfoFilterList]);

  useEffect(() => {
    dispatch(fetchSalepointInfoListData());
    dispatch(fetchSalepointInfoFilterList());
    dispatch(fetchSalepointInfoShopList());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const fetchPromises = [];
      fetchPromises.push(dispatch(refetchSalepointInfoListDataWithCursor()).unwrap());
      salepointInfoListCursors.forEach((cursorId) => {
        fetchPromises.push(
          dispatch(
            refetchSalepointInfoListDataWithCursor({
              cursor: EFetchSalepointInfoCursor.nextCursor,
              cursorId,
            })
          ).unwrap()
        );
      });

      Promise.all(fetchPromises).then((promisesData) => {
        let salepointInfoList: ISalepointInfoListItem[] = [];
        promisesData.forEach((data) => {
          salepointInfoList = [...salepointInfoList, ...data.data.salepoint_info_list];
        });
        dispatch(setSalepointInfoList(salepointInfoList));

        const lastReturnedData = promisesData[promisesData.length - 1];
        dispatch(setLastSalepointInfoListDataWithCursorRefetched(lastReturnedData));
      });
    }, DEFAULT_UPDATE_SALEPOINT_INFO_LIST_DATA_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [dispatch, salepointInfoListCursors]);

  useEffect(() => {
    if (
      selectedSorting ||
      tableColumnArrow !== ESlalepointInfoListTableColumnArrow.none ||
      selectedFilter ||
      searchByShopAddress
    ) {
      dispatch(clearSalepointInfoListCursors());
      dispatch(clearLastSalepointInfoListDataWithCursorRefetched());
      dispatch(fetchSalepointInfoListData());
    }
  }, [dispatch, selectedSorting, tableColumnArrow, selectedFilter, searchByShopAddress]);

  const handleSelectedFilterChange = (filterId: string) => {
    dispatch(setSelectedFilter(filterId));
  };

  const handleShopIdFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setShopIdFilter(event.target.value));
  };

  const handleShopIdFilterKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(fetchSalepointInfoListData());
    }
  };

  const handleShopAddressSelect = (shopId: number) => {
    dispatch(setSearchByShopId(shopId));
    dispatch(fetchSalepointInfoListData());
  };

  const handleShopAddressChange = (address: string) => {
    dispatch(setSearchByShopAddress(address));
    dispatch(fetchSalepointInfoListData());
  };

  return (
    <SalepointInfoListDashboardContainer>
      <SalepointInfoListDashboardControlsWrapper>
        <CustomSelectDwp
          label="Фильтр"
          notSelectedOptionText="Не выбран"
          optionValues={filterListOptionValues}
          optionTexts={filterListOptionTexts}
          value={selectedFilter?.id}
          onChange={handleSelectedFilterChange}
          selectSize={isLaptopSmallerScreenWidth ? 'small' : 'medium'}
        />
        <ControlsWrapperBottom>
          <SearchInputDwp
            label="№"
            value={shopIdFilter}
            onChange={handleShopIdFilterChange}
            onKeyUp={handleShopIdFilterKeyUp}
            sx={{ width: '100px', mr: '12px' }}
            size={isLaptopSmallerScreenWidth ? 'small' : 'medium'}
          />
          <ShopAddressSelect
            label="Адрес магазина"
            value={searchByShopAddress}
            sxWrapper={{ width: '350px', '@media (max-width: 1700px)': { width: '268px' } }}
            shopGroupList={shopGroupList}
            handleMenuItemClick={handleShopAddressSelect}
            onChange={handleShopAddressChange}
            inputSize={isLaptopSmallerScreenWidth ? 'small' : 'medium'}
          />
        </ControlsWrapperBottom>
      </SalepointInfoListDashboardControlsWrapper>
      <SalepointInfoListTableWrapper>
        <SalepointInfoListTable />
      </SalepointInfoListTableWrapper>
    </SalepointInfoListDashboardContainer>
  );
};

export default SalepointInfoListDashboard;
