import Box from '@mui/material/Box';
import styled from 'styled-components';

export const MenuListItem = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  color: #329eec;
  margin-bottom: 12px;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1700px) {
    font-size: 13px;
  }
`;
