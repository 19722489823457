import styled from 'styled-components';

export const Amount = styled.div`
  color: black;
  font-weight: 400;
  font-size: 20px;

  @media (max-width: 1700px) {
    font-size: 15px;
  }
`;
