import styled from 'styled-components';

export const DispatcherName = styled.div`
  margin: 0 0 5px 40px;
  font-weight: 500;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.dispatchersWorkplace.sidebarMenu.dispatcherName};

  @media (max-width: 1700px) {
    margin: 0 0 5px 25px;
    font-size: 18px;
    line-height: 18px;
  }
`;
